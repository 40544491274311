@import '../../App';

.install-prompt {
  display: flex;
  height: 3.5rem;
  background-color: @white;
  z-index: @zIndexSideNav;

  &__img {
    height: 100%;
    width: auto;
    padding: 0.25rem;
  }

  &__message {
    flex-grow: 1;
    margin: 0;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
  }

  &__close {
    margin: 0 !important;
    padding: 0 !important;
    width: 3.5rem;
    height: 3.5rem;
  }
}