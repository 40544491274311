@import '../../App';

.text-field {

  &__input {
    width: 100%;
    font-size: 1rem;
    border: 1px solid @border-color-light;
    outline: 0;
    padding: 5px 7px;
    height: 40px;
  }

  &__label {
    display: block;
  }
}