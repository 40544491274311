/**
    Colors
**/

@white: #fff;
@grey-100: #f9fafb;
@grey-300: #e5e5e5;
@grey-400: #dededf;
@grey-500: #707070;
@grey-700: #4c4c4c;
@grey-900: #2b2b2b;

@royal-blue-800: #002340;
@royal-blue-600: #003866;
@royal-blue: #004680;
@royal-blue-400: #004D8C;
@royal-blue-300: #0070CC;
@royal-blue-muted: #4276A1;
@royal-blue-200: #d0e4f3;
@royal-blue-100: #f4faff;

@warning-red: #FFCFCF;
@warning-red-500: #FA4843;

@cobalt: #4A5D5E;
@cobalt-muted: #758687;

@margarita-green-300: #ceee7e;
@margarita-green: #B7D861;
@margarita-green-500: #98c12b;

@shifty-orange: #F7931E;

@border-color: @grey-500;
@border-color-light: @grey-400;

@default-font: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
@default-font-color: #333333;

@globalPaddingMedium: 1rem;

@budget-row-height: 31px;
@budget-row-font-size: 1rem;

@primaryColor: @royal-blue;

/** Leftovers from sass */
@padding-small-padding: 4px;
@global-small-font-size: 12px;
@background-muted-background: #f8f7fa;
/** End leftovers from sass

/**
  Responsive width breakpoint
 */

@small-screen-max-width: 1023px;

/**
    Z-Index
 */
@zBackground: 1;
@zMainContent: 20;
@zModal: 20;
@zIndexSideNav: 50;

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
}

#app{
  background-color: @background-muted-background;
  height: 100%;
}