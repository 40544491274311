.split-row-footer {
  &__row-layout {
    display: flex;
  }

  &__row-layout-cell {
    flex-grow: 1;
    padding: 8px 9px 8px 5px;

    &--description {
      text-align: right;
    }
  }
}