@import '../../App';

.category-label {
  width: 100%;
  height: @budget-row-height;
  border: 1px dotted @border-color;
  border-top: 0;
  text-align: left;
  padding: 1px 5px 1px 0;
  display: flex;
  align-items: center;
  background-color: white;

  &__drag-handle {
    display: inline-block;
    padding: 5px 8px 5px 10px;
    color: @grey-500;
  }

  &--master-category, &--hidden-categories {
    background-color: @grey-300;
  }

  &__text {
    font-weight: normal;
    font-size: 1rem;
    white-space:nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &--master-category {
      font-weight: bold;
    }

    &--hidden-categories {
      font-weight: bold;
      padding-left: 12px;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__edit-icon {
    display: none !important;
    padding-left: 4px !important;
    color: @grey-500;
  }

  &__add-button {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &:hover .category-label__edit-icon {
    display: inline !important;
  }
}