@import '../../../App';

.filters-modal {
  background-color: @white;

  &__top {
    height: 38px;
    border-bottom: @border-color-light 1px solid;
    text-align: right;
  }

  &__close {
    margin: 0.5em 0.5em 0 0 !important;
  }

  &__select {
    width: 100%;
    border: 0;
    outline: none;
    min-height: 3rem;

    option {
      font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-size: 1rem;
      padding: 0.75rem 0.75rem;
    }
  }
}