@import "../../styles/mixins/cell";

.currency-cell {
   min-width: 60px;
   width: 100%;
   margin: 0;

   &__input {
     .cell();

     border: 0;
     outline: none;
     text-align: right;
     background: none;
     cursor: pointer;

     -moz-appearance:textfield;
     &::-webkit-inner-spin-button {
       -webkit-appearance: none;
       margin: 0;
     }
   }

   &--disabled {
     font-weight: bold;
     color: #000;
     cursor: auto;
   }
 }