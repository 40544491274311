@import "../../../App";

.date-range-filter {
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  &__input {
    border: 1px solid @grey-400;
    padding: 3px 8px;
    font-size: 1rem;
    height: 40px;
    outline: none;

    &--from {
      border-radius: 0;
    }

    &--to {

    }
  }

  &__type {
    display: flex;
    flex-direction: row;
  }

  &__custom {
    display: flex;
    flex-direction: row;
    margin-left: 5px;

    > div {
      flex-grow: 1;
    }

    @media only screen and (max-width: 1023px) {
      margin: 0;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    background-color: @grey-400;
    //border-radius: 25px 0 0 25px;
    padding: 0 10px 0 10px;
    vertical-align: center;
    font-weight: bold;
    font-size: 1rem;

    &--from {

    }

    &--to {
      border-radius: 0;
    }
  }

  &__select {
    border: 1px solid @border-color-light;
    border-left: 0;
    min-width: 150px;
    flex-grow: 1;
  }
}