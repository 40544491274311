@import "../../App";

.app-frame {
  z-index: @zMainContent;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content-area {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display:flex;
  }

  &__content-width {
    height: 100%;
    width: 100%;
    flex-grow: 1;

    @media only screen and (max-width: 1023px) {
      display: flex;
      flex-direction: column;
      height: auto;
    }
  }
}