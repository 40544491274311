@import '../../App';

@keyframes inner-glow {
    from {
        box-shadow: inset fade(@royal-blue-300, 60%) 0 0 5px;
    }
    to {
        box-shadow: none;
    }
}

.transaction-row {
    border-bottom: 1px solid @border-color-light;
    padding-top: 3px;

    &--striped {
        background-color: #FAFAFB;
    }

    &--glowing {
        animation: inner-glow 8s;
    }

    &__main-row {
        display: flex;
        height: 100%;
        flex-direction: row;
    }

    &__split-row {
        display: flex;
        flex-direction: row;
    }

    &__spacer {
        flex-basis: 20%;
        flex-grow: 2;
    }

    &__cell {
        display: flex;
        align-items: center;
        height: 100%;
        box-sizing: border-box;

        &:first-child {
            padding-left: 20px;
        }

        flex-grow: 1;
        flex-basis: 10%;
        padding: 4px;
    }

    &__editable {
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        width: 100%;
        height: 100%;
        white-space: nowrap;
    }

    &__uneditable {
        text-overflow: ellipsis;
        overflow: visible;
        display: inline-block;
        width: 100%;
        height: 100%;
        white-space: nowrap;
    }
}