@import '../../App';

.month-sheet {
    flex-grow: 1;
    flex-basis: 0;
    margin-left: 5px;

    &__row {
        display: flex;
        height: @budget-row-height;

        &--last {
            .month-category-cell--budgeted {
                border-bottom-width: 1px;
            }
        }

        &--first {
            .month-category-cell--budgeted {
                border-top: solid 1px @margarita-green;
            }

            .month-category-cell:not(:first-child) {
                border-top: @border-color dotted 1px;
            }
        }
    }

    &__category-totals {
        display: flex;
        height: @budget-row-height;
    }
}