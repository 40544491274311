@import '../../App';

.balance-pill {
  &--negative {
    width: auto;
    background-color: @warning-red;
    border-radius: 5px;
    padding: 2px 5px;
  }

  &--positive {
    width: auto;
    background-color: @margarita-green-300;
    border-radius: 5px;
    padding: 2px 5px;
  }
}