
@import '../../App';

.settings-page {
  padding: @globalPaddingMedium;
  height: 100%;
  overflow: auto;

  &__section {
    padding-top: @globalPaddingMedium;
  }
}