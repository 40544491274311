@import "../../App";

.master-category-card {
  margin: 5px;

  &__title {
    font-size: 1.07rem;
    padding: 0.3rem 0.3rem 0.3rem 1rem;
    border: 1px solid @royal-blue-800;
    margin: 0;
    background-color: @royal-blue;
    color: @white;
    text-shadow: #000 1px 1px 1px;
    display: flex;

    span {
      flex-grow: 1;
    }
  }

  &__edit {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  &__cells {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid @royal-blue-200;
    border-left: 1px solid @royal-blue-200;
    background-color: @royal-blue-100;
  }

  &__cell {
    flex-basis: 0.33%;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 0.75rem 1rem;
    text-align: right;
    border-right: 1px solid @royal-blue-200;
  }

  &__label {
    display: inline-block;
    font-weight: bold;
  }

  &__value {
    white-space: nowrap;
  }
}