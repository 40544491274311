@import '../../App';

.profiles-page {
  &__bg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: @royal-blue;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1023px) {
      padding-top: 15px;
    }
  }

  &__title {
    text-align: center;
    display: block;
    flex-shrink: 0;
    flex-basis: 50px;
  }

  &__logo {
    height: 50px;
  }

  &__main-box {
    display: flex;
    flex-direction: column;
    background-color: @white;
    min-width: 350px;
    max-height: 100%;
    padding: 20px 20px 40px 20px;

    @media only screen and (max-width: 1023px) {
      height: 100%;
      width: 100%;
      margin: 0 15px;
    }
  }

  &__add-profile {
    display: block;
    padding: 10px 0;
  }

  &__list {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0 20px;
    flex-grow: 1;
  }

  &__list-item {
    border-bottom: 1px solid @border-color-light;
  }

  &__list-link {
    display: block;
    padding: 10px 0;
  }
}
