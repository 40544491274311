@import '../../App';

.budget-tools {
    height: 155px;

    &__top {
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding-bottom: 10px;
        padding-right: 5px;
    }

    &__button {
        margin-top: 5px !important;
    }

    &__button-group {
        width: 100%;
    }

    &__row {
        display: flex;
    }

    &__category-rows {
        padding: 20px 0px 20px 20px;
        border-radius: 3px;
    }
}