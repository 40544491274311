@import '../../App';

.budget-sheet-empty-state {
  height: calc(100% - 20px);
  width: 100%;
  position: absolute;
  z-index: @zModal;

  &__message {
    margin: auto !important;
    position: relative;
    top: 25%;
    width: 50%;
    min-width: 300px;
  }

  &__button {
    @media only screen and (max-width: @small-screen-max-width) {
      width: 100%;

      &:last-child {
        margin-top: 1rem;
      }
    }
  }
}