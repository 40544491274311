@import '../../App';

.transactions-table {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-grow: 1;
	border-collapse: collapse;
	font-size: 1rem;
	text-align: left;
	background-color: @white;
	overflow: hidden;

	&__wrap {
		display: flex;
		flex-direction: column;
		margin: 0;
		flex-grow: 1;
		overflow: hidden;
	}

	&__rows {
		flex-grow: 1;
		border-bottom: 1px solid #ccc;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	&__list-window-parent {
		flex-grow: 1;
		overflow: hidden;
	}

	&__list-window {
		overflow-y: overlay !important;
		border-left: 1px solid @border-color-light;
		border-right: 1px solid @border-color-light;

		@media only screen and (max-width: 1023px) {
			overflow-y: auto !important;
			padding-bottom: 55px;
		}
	}

	&__header-row {
		display: flex;
		flex-direction: row;
		height: 40px;
		border-left: @border-color-light 1px solid;
		border-right: @border-color-light 1px solid;
		background-color: @grey-100;
	}

	&__header-column {
		&:first-child {
			padding-left: 20px;
		}

		display: flex;
		align-items: center;
		font-size: 1rem;
		font-weight: bold;
		border-bottom: @border-color-light 1px solid;
		border-top: @border-color-light 1px solid;
		padding: 4px;
		flex-grow: 1;
		box-sizing: border-box;
	}

	&__icon {
		&--cleared,
		&--reconciled {
			color: @margarita-green;
		}
	}
}
