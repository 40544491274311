@import '../../../App';

.transactions-table-footer {
  width: 100%;
  padding: 10px 15px;
  min-height: 30px;
  display: flex;

  @media only screen and (max-width: 1023px) {
    position: absolute;
    bottom: 0;
  }

  &__left {
    @media only screen and (max-width: 1023px) {
      width: 100%;
    }
  }

  &__right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  &__totals {
    display: inline-block;
    //border: 1px solid @border-color-light;
    //padding: 5px;
  }
}