@import '../../App';

.cell-value {
  display: inline;
  width: 100%;
  text-align: right;

  &--negative {
    width: auto;
    background-color: @warning-red;
    border-radius: 5px;
    padding: 2px 5px;
    cursor: pointer;
  }
}