@import '../../App';

.month-totals-card {
  display: flex;
  font-size: 1rem;
  text-align: right;

  &__column-labels {
    display: flex;
    height: 20px;

    padding: 3px 4px;
    text-align: right;
    font-size: 1rem;
    font-weight: bold;
  }

  &__cell {
    flex-grow: 1;
    width: 33.3%;
    text-align: right;
    font-weight: bold;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid @royal-blue-200;
    border-left: 1px solid @royal-blue-200;
    background-color: @royal-blue-100;
  }
}