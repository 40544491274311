@import '../../App';

.multi-month-selector {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-shrink: 0;

  &__arrow {
    margin: 0 0.25em !important;
  }

  &__list {
    flex-grow: 1;
    display: flex;
  }

  &__list-item {
    flex-grow: 1;
    border: 0;
    background-color: @grey-300;
    padding: 10px 3px;
    font-weight: bold;
    font-family: @default-font;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: @grey-400;
    }

    &--visible {
      background-color: @cobalt-muted;
      color: @white;
      text-shadow: #000 1px 1px 1px;
    }

    &--current-month {
      color: @margarita-green;
      background-color: @cobalt;
    }
  }
}