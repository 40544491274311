@import "../../App";

.date-picker {
  width: 100%;
  &__input {
    width: 100%;
    padding: 8px 5px;
    font-size: 1rem;
    border: 1px solid @border-color-light;
  }

  > .react-datepicker-wrapper {
    width: 100%;
  }
}