@import '../../App';

.transactions-page {
  //background-color: @white;
  width: 100%;
  height: 100%;
  padding: @globalPaddingMedium;

  &__table-container {
    margin: 0 2rem;
  }
}