@import "../../App";

.sub-category-card {
  box-sizing: border-box;
  border-bottom: 1px solid @grey-300;
  padding: 0.6rem;

  &__header {
    display: flex;
  }

  &__header-icon {
    transition-property: rotate;
    transition-duration: 200ms;

    &--down {
      transform-origin: center center;
      rotate: 90deg;
    }
  }

  &__title {
    flex-grow: 1;
    margin: 0;
    padding: 0.25rem;
  }

  &__balance {
    display: flex;
    align-items: center;
  }

  &__progress-bar {
    box-sizing: border-box;
    border: 1px solid @grey-300;
    width: 100%;
    margin: 0.5em 0em 0.5em !important;

    &--success {
      .bar {
        background-color: @margarita-green !important;
      }
    }

    &--warning {
      .bar {
        background-color: @warning-red-500 !important;
      }
    }
  }

  &__cells {
    display: flex;
    height: 0;
    transition: all 200ms ease-in-out;
    overflow: hidden;

    &--expanded {
      height: 70px;
    }
  }

  &__cell {
    flex-basis: 0.33%;
    flex-grow: 1;
    padding: 0.5rem 0.25rem 0 0.25rem;
    text-align: center;

    &:first-child {
      text-align: left;
      span {
        text-align: left;
      }
    }

    &:last-child {
      text-align: right;
      span {
        text-align: right;
      }
    }
  }

  &__label {
    display: inline-block;
    font-weight: bold;
    font-size: 0.875rem;
    text-align: center;
    width: 100%;
    line-height: 1rem;
    padding-top: 0.25rem;
  }

  &__input {
    border: 0;
    width: 100%;
    height: 90%;
    text-align: center;
    background: none;
    border-radius: 0 !important;
    outline: @margarita-green dashed medium;
    font-family: inherit;
    margin-bottom: 0.5rem;

    &::selection {
      background-color: @margarita-green-300;
    }
  }

  &__outflows-link {
    color: @default-font-color;
  }

  &__outflows-icon {
    width: auto !important;
    position: relative;
    left: 3px;
    top: 2px;
    font-size: 1.125rem !important;
  }

  &__edit {
    padding-top: 0.6rem;
  }
}
