@import '../../../App';

.text-filter {
  display: flex;
  min-width: 350px;
  width: 100%;

  &__label {
    display: flex;
    height: 3rem;
    align-items: center;
    background-color: @grey-400;
    padding: 0 0.75rem;
    vertical-align: center;
    font-weight: bold;
    font-size: 1rem;
  }

  &__input {
    flex-grow: 1;
    border: 1px solid @grey-400;
    padding: 3px 8px;
    font-size: 1rem;
    height: 3rem;
    outline: none;
  }
}