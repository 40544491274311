@import '../../App.less';

.sign-up-form {
  &__submit {
  }

  &__error {
    color: @warning-red-500;
    display: inline-block;
    padding: 2px 0;
  }
}