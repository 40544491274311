@import '../../App';

.account-summary {
  display: flex;
  border: 1px solid @border-color-light;
  margin-right: 20px;

  @media only screen and (max-width: 1023px) {
    width: 100%;
    border: 0;
    margin: 0;
  }

  &__third {
    flex-basis: 33.3%;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0.75em 1.5em;

    @media only screen and (max-width: 1023px) {
      padding: 0;
    }
  }

  &__operator {
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  &__label {
    font-weight: normal;
    display: inline-block;
    width: 100%;
    font-size: 1rem;
    padding: 0.25em;
  }

  &__value {
    flex-grow: 1;
    width: 100%;
    padding-top: 0.25em;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
  }
}