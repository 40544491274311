@import '../../App';

.add-profile-page {
    max-width: 300px;

	&__actions {
		margin-top: 10px;
        display: flex;
        justify-content: space-between;
	}

	&__back {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__currency-selector {
      margin: 0.25rem 0;
      border: 1px solid @border-color-light;
    }
}
