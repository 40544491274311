@import '../../App';

.top-nav {
  display: flex;
  background-color: @royal-blue;
  flex-shrink: 0;
  box-shadow: 0 0px 4px 0px rgba(@royal-blue-800, 75%);
  z-index: @zMainContent;

  &__content {
    flex-grow: 1;
  }

  &__kabob {
    float: right;
    background-color: @royal-blue !important;
    color: #fff !important;
    font-size: 18px !important;
  }

  &__title {
    flex-grow: 1;
    display: flex;
    color: @white;
    font-size: 1rem;
    align-items: center;
    margin: 0;
    height: 100%;
  }
}