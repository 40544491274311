@import '../../App';

.tags-cell {
  &__icon {
    color: @royal-blue-400;

    &--none{
      color: @grey-300 !important;
    }
  }
}