@import '../../App';

.native-date-picker {
  border: 1px solid @border-color-light;
  padding: 10px 5px;
  width: 100%;
  outline: none;
  background-color: white;
  -webkit-appearance: none;
  color: @grey-900;

  &__label {
    display: block;
  }

  &::-webkit-calendar-picker-indicator {
    outline: none;
    cursor: pointer;
    border-radius: 12px;

    &:hover {
      background-color: @border-color-light;
    }
  }

  &::-webkit-datetime-edit {
    width: 100px;
  }
}