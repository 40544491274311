@import '../../../App';

.add-transaction-row {
    display: flex;
    flex-direction: column;
    border: 1px solid @border-color-light;
    border-bottom: 0;
    background-color: @grey-100;


    &__split-row {
        display: flex;
        flex-direction: row;
        padding-top: 5px;
    }

    &__main-row {
        display: flex;
        flex-direction: row;
    }

    &__main-row-cell {
        flex-grow: 1;
        height: 33px;
        padding-right: 4px;

        &--status {
            cursor: pointer;
        }
    }

    &__split-row-cell {
        flex-grow: 1;
        height: 31px;
        box-sizing: border-box;
        padding-right: 4px;

        &--split-actions {
            text-align: right;
        }
    }

    &__row-actions {
        display: flex;
        flex-direction: row-reverse;
        padding: 6px;
    }

    &__date-picker {
        width: 100%;
        border: 1px solid @border-color-light;
        height: 33px;
        outline: none;
    }

    &__select {
        height: 33px;
        background-color: @white;
        border: 1px solid @border-color-light;

        &--disabled {
            opacity: 0.7;
        }
    }

    &__input {
        height: 33px;
        box-sizing: border-box;
        border: 1px solid @border-color-light;
        width: 100%;
        padding: 8px 5px;
        outline: none;
    }

    &__error-message {
        color: @warning-red-500;
        padding-left: 0.5em;
    }
}