@import '../../App';

.profiles-list-page {
    &__add-profile {
      display: block;
      padding: 10px 0;
    }

	&__list {
		list-style: none;
		padding: 0;
		margin: 0 20px;
		overflow-y: auto;
		flex-grow: 1;
	}

	&__list-item {
		border-bottom: 1px solid @border-color-light;
	}

	&__list-link {
		display: block;
		padding: 10px 0;
	}
}
