@import '../../App.less';

.accounts-nav {
  padding: 0;
  border-top: 1px solid @royal-blue-muted;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__top {
    display: flex;
    padding: 8px 0;
    min-height: 40px;
  }

  &__heading {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    color: @white;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 1rem;
    margin: 0px;
  }

  &__section-container {
    max-height: 100%;
    overflow-y: auto;
  }

  &__section {
    overflow: hidden;
    border-top: 1px solid @royal-blue-muted;
  }

  &__accounts-list {
    margin: 0;
    padding: 0 0 5px 0;
    list-style: none;
    height: 100%;
  }

  &__accounts-list-item {
    margin: 0;
  }

  &__closed-accounts {
    padding-top: 10px;
  }

  &__balance {
    display: inline-block;
    float: right;
  }

  &__add-acct-btn {
    position: relative;
    right: 3px;
    float: right;
  }
}