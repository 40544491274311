@import '../../App';

.sync-page {
  width: 100%;
  height: 100%;
  padding: @globalPaddingMedium;
  overflow-y: auto;

  &__device-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;

    @media only screen and (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
  }

  &__empty-state {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}