@import '../../App.less';

.account-link {
  color: @white;
  padding: 8px 0px 8px 13px;
  display: flex;

  &__name {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    padding-right: 0.2rem;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }

  &--active {
    background-color: @royal-blue-muted;
  }
}