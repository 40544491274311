@import '../../App';

@keyframes glow {
	from {
		background-color: fade(@royal-blue-300, 10%);
	}
	to {
		background-color: @white;
	}
}

.compact-transaction-row {
	border-top: 1px solid @border-color-light;
	padding: 5px;
	height: 65px;

	&--glowing {
		animation: glow 5s;
		box-shadow: inset #fff 0 0 5px;
	}

	&__date-header {
		color: @royal-blue;
		border-bottom: 3px solid @royal-blue;
		font-weight: bold;
		font-size: 0.85rem;
		padding: 2px 5px;
		height: 24px;
	}

	&__tag {
		color: @royal-blue-400;
	}

	&__upper,
	&__lower {
		display: flex;
	}

	&__upper {
		padding-bottom: 5px;
	}

	&__payee {
		display: inline-block;
		flex-grow: 1;
		font-size: 1rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__category,
	&__notes {
		display: inline-block;
		flex-grow: 1;
		white-space: nowrap;
	}

	&__notes {
		color: @grey-500;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-right: 0.5em;
	}

	&__amount,
	&__category {
		text-align: right;
	}

	&__amount {
		flex-grow: 0;
		display: inline-block;
		font-size: 1rem;

		&--outflow {
			color: @warning-red-500;
		}

		&--inflow {
			color: @margarita-green-500;
		}
	}
}
