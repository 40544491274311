@import "../../App";

.month-header {
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 5px;
    min-width: 270px;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--small-screen {
        height: auto;
        border-top: 1px solid @royal-blue-muted;
        margin-right: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &__loader {
        margin-top: 40px;
    }

    &__month-navigation {
        display: flex;
        flex-direction: row;
    }

    &__arrow {
        background-color: @royal-blue-600 !important;
        margin: 0 !important;
    }

    &__top {
        display: flex;
        flex-direction: column;
        background-color: @grey-300;
        text-align: center;
        padding-bottom: 10px;
        flex-grow: 1;

        &--current {
            background-color: @cobalt;
            color: @white;
            text-shadow: #000 1px 1px 1px;
        }

        &--current-mobile {
            background-color: @royal-blue-600;
            color: @white;
            text-shadow: #000 1px 1px 1px;
        }

        &--future {
            background-color: @cobalt;
            color: @white;
            text-shadow: #000 1px 1px 1px;
        }
    }

    &__month-heading {
        flex-grow: 0;
        margin: 0;
        padding: 10px 0 0 0;
        text-align: center;
        font-size: 15px;

        &--current {
            color: @white;
        }

        &--future {
            color: @white;
        }
    }

    &__avail-to-budget {
        font-size: 34px;
        display: inline-block;
        padding: 20px 0 10px 0;

        &--current {
            color: @margarita-green;
        }

        &--future {
            color: @white;
        }

        &--negative {
            color: @warning-red;
        }
    }

    &__column-label {
        width: 33.3%;
        flex-grow: 1;
        text-align: right;
        font-size: 1rem;
    }
}