@import '../../../App';

.outflow-inflow-field {

  &--small {

  }

  &__input-group {
    display: flex;
  }

  &__input {
    font-size: 32px;
    width: 100%;
    outline: none;
    text-align: right;
    color: @white;
    background-color: @grey-900;
    border: 0;
    padding: 15px 10px;

    &--small {
      padding:2px  5px;
    }
  }

  &__type-selector {
    width: 100%;
  }

  &__button {

    &--faded {
      opacity: 0.25;
    }
  }
}