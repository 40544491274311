@import "../../App";

@z-index-side-nav: 50;
@z-index-side-nav-bg: 49;

.side-bar {
  height: 100%;
  flex-basis: 25%;
  min-width: 250px;

  &--hidden {
    display: none;
  }

  &__background {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    z-index: @z-index-side-nav-bg;
  }

  @media only screen and (max-width: 1023px) {
    position: absolute;
    z-index: @z-index-side-nav;
    width: 55%;
    min-width: 250px;
    transform: translateX(-110%);
  }

  @media only screen and (max-width: 639px) {
    position: absolute;
    z-index: @z-index-side-nav;
    width: 85%;
  }
}

/**
  Fade Animation
 */
.fade-animation-enter {
  display: block;
  animation: fade-in 250ms forwards ease;
}

.fade-animation-enter-done {
  display: block;
}

.fade-animation-exit-active {
  display: block;
  animation: fade-out 250ms forwards ease;
}

.fade-animation-exit-done {
  display: none;
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

/**
  Slide Animation
 */
.slide-animation-enter {
  animation: slide-in 0.4s forwards ease;
  -webkit-animation: slide-in 0.4s forwards ease;
}

.slide-animation-enter-done {
  transform: translateX(0%);
}

.slide-animation-exit {
  animation: slide-out 0.4s forwards ease;
  -webkit-animation: slide-out 0.4s forwards ease;
}

@keyframes slide-in {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-110%); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(-110%); }
}