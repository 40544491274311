@import '../../App';

.accounts-top-nav-tools {
  display: flex;

  &__heading {
    flex-grow: 1;
    display: flex;
    color: @white;
    font-size: 1rem;
    align-items: center;
    margin: 0;
  }

  &__filter-btn {
    position: relative;
  }

  &__filter-badge {
    background-color: @warning-red-500;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    position: absolute;
    top: 10px;
    right: 16px;
    z-index: @zModal;
    visibility: hidden;

    &--filtering {
      visibility: visible;
    }
  }
}