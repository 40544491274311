@import '../../App';

.master-category-totals {
    &__cell {
        width: 33.3%;
        flex-grow: 1;
        text-align: right;
        background-color: #e5e5e5;
        height: @budget-row-height;

        display: flex;
        align-items: center;
        justify-content: left;

        border-top: 0;
        border-right: 1px dotted @border-color;

        &:first-child {
            border-left: @border-color dotted 1px;
        }
    }

    &__value {
        color: @default-font-color;
        width: 100%;
        padding-right: 8px;
        font-weight: bold;
        line-height: normal;
    }

    &__outflows-link {
        color: #000;

        &:hover {
            color: #000;
            text-decoration: underline;
        }
    }
}

// TODO: Make this less of a hack
.month-sheet__category-totals--first {
    .master-category-totals__cell {
        border-top: 1px dotted @border-color;
    }
}