@import '../../App';

// Can't call "button" because it would conflict with semantic
.app-button {
  text-shadow: #333333 2px 2px;

  &--primary {
    background-color: @royal-blue !important;
    color: @white !important;
  }

  &--success {
    background-color: @margarita-green-500 !important;
    color: @white !important;
  }

  &--warning {
    background-color: @warning-red-500 !important;
    color: @white !important;
  }
}