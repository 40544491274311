@import '../../App';

.pill {
  border-radius: 0.3125rem !important;

  &--default {
    color: @white !important;
    background-color: @royal-blue-muted !important;
  }

  &--warning {
    background-color: @warning-red !important;
  }

  &--success {
    background-color: @margarita-green !important;
  }

  &--muted {
    background-color: @grey-400 !important;
  }
}