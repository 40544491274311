@import "../../App";

.month-header-popup-content {
  &__title {
    padding: 2px 5px;

    &--total {
      border-top: 1px solid @border-color-light;
      font-weight: bold;
    }
  }

  &__value {
    text-align: right;

    &--total {
      border-top: 1px solid @border-color-light;
      font-weight: bold;
    }
  }

  &__totals-row {

  }
}