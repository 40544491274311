@import '../../App.less';

.main-nav {
  display: flex;
  flex-direction: column;
  background-color: @royal-blue;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0;
  box-shadow: rgba(0,0,0,0.20) 2px 0 4px;
  z-index: 5;
  position: relative;

  &__top {
    display: flex;
  }

  &__top-actions {
    flex-grow: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }

  &__logo {
    color: @white;
    padding: 7px;
    height: 50px;
  }

  &__profile {
    display: flex;
    border-bottom: 1px solid @royal-blue-muted;
    border-top: 1px solid @royal-blue-muted;
    padding: 10px 15px;
    color: @white;
  }

  &__profile-name {
    flex-grow: 1;
  }

  &__edit-profile {
    flex-basis: 40px;
    cursor: pointer;
  }

  &__change-profile {
    float: right;
    color: @white;

    &:hover {
      color: @border-color-light
    }
  }

  &__section {
    margin-bottom: 10px;
  }

  &__heading {
    color: @white;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 13px;
    margin-bottom: 10px;
  }

  &__list, &__sub-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item-link {
    width: 100%;
    color: @white;
    padding: 0.5rem 1rem;
    display: inline-block;
    background: none;
    border: 0;
    cursor: pointer;
    outline: none;
    text-align: left;

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }

    &--active {
      background-color: @royal-blue-muted;

      &:has(+ .main-nav__sub-list .main-nav__sub-list-item-link--active) {
        background: none;
      }
    }
  }

  &__sub-list-item-link {
    width: 100%;
    color: @white;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
    display: inline-block;
    background: none;
    border: 0;
    cursor: pointer;
    outline: none;
    text-align: left;

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }

    &--active {
      background-color: @royal-blue-muted;
    }
  }
}