@import '../../App.less';

.sync-bar {
  background-color: @royal-blue-600;
  padding: 0.75em 0.5em;
  color: @white;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: @white;
  }

  &--warning {
    background-color: @shifty-orange;
  }

  &--success {
    background-color: @margarita-green;
  }
}