@import '../../../../App';

.splits-modal {
  &__each-split {
    border: 1px solid @border-color-light;
    margin-bottom: 10px;
  }

  &__split-amount {

  }

  &__split-category {
    display: flex;

  }

  &__input {
    flex-grow: 1;
    width: 100%;
  }
}