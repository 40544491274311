@import "../../App";

.transaction-status {
  &--cleared {
    color: @margarita-green;
  }

  &--uncleared {
    opacity: 0.2 !important;
  }

  &--reconciled {
    color: @margarita-green;
  }
}