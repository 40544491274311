@import '../../App';

.account-header {
  padding: 20px 20px 0 20px;
  background-color: @white;
  box-shadow: rgba(0,0,0,0.15) 0 0 6px;
  display: flex;

  @media only screen and (max-width: 1023px) {
    padding: 0;
  }

  &__heading-container {
    flex-grow: 1;
    margin-right: 20px;
  }

  &__heading {
    flex-grow: 1;
  }
}