@import "../../App";

.budget-page {

  flex-grow: 1;
  height: 100%;
  background-color: @white;

  &--small-screen {
    height: auto;
    overflow: hidden;
  }

  &__dimmer {
    position: relative;
    height: 100%;
  }

  &__content {
    box-sizing: border-box;
    display: flex;
    height: 100%;
    padding: 20px;
    flex-direction: column;
    border-radius: 3px;
  }

  &__category-tools {
    min-width: 275px;
    width: 18vw;
  }

  &__list {
    height: 100%;
    overflow-y: auto;
  }

  &__header {
    display: flex;
    margin-top: 20px;
    flex-shrink: 0;
  }

  &__sheet-header {
    flex-grow: 1;
    box-sizing: border-box;
    border-radius: 3px 3px 0 0;
    margin-left: 5px;
  }

  &__month-headers {
    display: flex;
    overflow-y: scroll;
    height: 100%;

  }

  &__month-sheets {
    overflow-y: auto;
  }

  &__month-sheets {
    display: flex;
    flex-direction: row;
  }

  &__add-transaction-btn {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    padding: 0 1rem;
  }
}