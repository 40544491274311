@import '../../App';

.month-category-cell {
	width: 33.33%;
	height: @budget-row-height;
	padding: 0;
	margin: 0;
	border-right: @border-color dotted 1px;
	border-bottom: @border-color dotted 1px;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;

	&--past-budgeted {
		border: solid 1px @border-color;
		border-top-width: 0;
	}

	&--budgeted {
		border: solid 1px @margarita-green;
		border-top-width: 0;
	}

	&__cell-value {
		padding-right: 8px;

		&--negative {
			padding: 2px 5px;
			margin-right: -2px;
		}
	}

	&__outflows-link {
        color: #000;

		&:hover {
            color: #000;
			text-decoration: underline;
		}
	}

	input::selection {
		background-color: @margarita-green-300;
	}
}

// TODO: Make this less of a hack
.month-sheet__row--first {
	.month-category-cell--past-budgeted {
		border-top-width: 1px;
	}
}
