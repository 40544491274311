@import '../../App';

.budget-sheet {
	display: flex;
	overflow: hidden;
	box-sizing: border-box;
	background-color: @white;
	border-radius: 3px;
	padding: 0;
	flex-grow: 1;
	position: relative;

	&__window {
		display: flex;
		overflow-y: scroll;
		box-sizing: border-box;
		width: 100%;

		&--burred {
			margin-top: 10px;
			filter: blur(2.5px);
			opacity: 0.55;
		}
	}

	&__category-rows {
		padding-right: 5px;
		min-width: 275px;
		width: 18vw;
	}

	&__month-sheets {
		flex-grow: 1;
		display: flex;
		box-sizing: border-box;
	}

	&__budget-category-row {
		&--first {
			border-top: @border-color dotted 1px;
		}
	}
}
