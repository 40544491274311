@import '../../App';

.sign-up-page {
  overflow: auto;
  height: 100%;
  width: 100%;

  &__bg {
    display: flex;
    flex-direction: column;
    background: transparent url('../../../../public/slanted.svg') no-repeat scroll 0 bottom/100% auto;
    background-color: @royal-blue;
    align-items: center;
    justify-content: center;
    overflow: auto;
    min-height: 100%;
    width: 100%;
  }

  &__title {
    text-align: center;
    display: block;
    flex-shrink: 0;
    flex-basis: 50px;
  }

  &__logo {
    height: 75px;
    margin: 3em 3em 4em 3em;

    @media only screen and (max-width: 600px) {
      height: 55px;
      margin: 0.75em 0.75em 1.5em 0.75em;
    }
  }

  &__main-box {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    padding: 1em;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &__form-container {
    background-color: @white;
    padding: 1.5em;
    border: 1px solid @border-color-light;
    box-shadow: 0 1px 3px 0 rgba(@royal-blue-800, 0.15);
  }

  &__tab-frame{
    min-height: 420px;

    .item:not(.active) {
      color: @white !important;
    }
  }

  &__add-profile {
    display: block;
    padding: 10px 0;
  }

  &__list {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0 20px;
    flex-grow: 1;
  }

  &__list-item {
    border-bottom: 1px solid @border-color-light;
  }

  &__list-link {
    display: block;
    padding: 10px 0;
  }

  &__login-submit {
    margin-top: 1rem !important;
  }
}
