@import "../../App";

.sync-devices-empty-state {
  margin: 3em !important;
  background-color: @white !important;

  @media only screen and (max-width: 1023px) {
    margin: 1.5em !important;
  }

  &__icon {
    display: inline-block !important;
    margin: 0.125em !important;
  }

  &__title {
    display: block;
  }

  &__subtitle {
    text-align: center;
    font-size: 1.125em;
  }
}