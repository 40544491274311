@import '../../App';

.month-totals {
  display: flex;
  font-size: 1rem;
  text-align: right;

  &__column-labels {
    display: flex;
    height: 20px;

    padding: 3px 4px;
    text-align: right;
    font-size: 1rem;
    font-weight: bold;
  }

  &__cell {
    flex-grow: 1;
    width: 33.3%;
    text-align: right;
    font-weight: bold;
    border: 1px dotted @border-color;
    border-left: 0;
    border-top: 0;
    padding-right: 8px;
  }

  &__cell:first-child {
    border-left: 1px dotted @border-color;
  }

  &__value {

  }
}