@import "../../../App";

.add-edit-transactions-modal {

  &__split-button {
    width: 100%;
    margin: 0;
    outline: 0;
    border: 0;
    background: none;
    text-align: left;
    padding: 5px  5px 5px;
  }

  &__field {
    border: @border-color-light 1px solid;
    border-bottom: 0;
    padding: 7px 0 7px 7px;

    &--error {
      border: 1px solid @warning-red-500;
      box-shadow: inset 0 0 3px @warning-red-500;
    }

    &:last-child {
      border-bottom: @border-color-light 1px solid;
    }
  }
}