@import '../../../App';

.simple-button {
  border: 0;
  outline: 0;
  cursor: pointer;
  background: none;
  color: #4183C4;
  padding: inherit;

  &--inverted {
    color: @white
  }
}