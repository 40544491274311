@import '../../App';

.reconcile-summary {
  display: flex;
  flex-direction: row;
  z-index: @zMainContent;

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  &__bar {
    background-color: @warning-red-500;
    color: @white;
    text-align: center;
    padding: 0.25em 0;
    font-weight: bold;
  }
}