@import '../../../App';

.table-tools {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1023px) {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  &__header {
    padding: 1rem;
    display: flex;

    @media only screen and (max-width: 1023px) {
      flex-direction: column;
      padding: 0;
    }
  }

  &__heading {
    display: inline-block;
    flex-grow: 1;
  }

  &__toolbar {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: @padding-small-padding @padding-small-padding 0 @padding-small-padding;
    border-bottom: 1px solid #ccc;
  }

  &__toolbar-buttons {
    height: 40px;
    align-items: flex-end;
    flex-direction: column;
    flex-grow: 1;

    // Hack for getting semantic ui buttons the right height
    & > div {
      height: 40px;
    }
  }

  &__filters {
    padding: 0 1rem 1rem 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 1023px) {
      padding: 0;
    }
  }

  &__each-filter {
    @media only screen and (max-width: 1023px) {
      width: 100%;
      border-bottom: 2px solid @white;

      &:last-child {
        border: 0;
      }
    }
  }

  &__filter-heading {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-size: @global-small-font-size;
    font-weight: bold;
    margin: 0;
    padding: 4px 4px 4px 20px;
  }

  &__filter-items {
    display: flex;
    //flex-direction: row-reverse;
  }
}